// src/components/PostDetail.js
import React from 'react';
import { useParams } from 'react-router-dom';
import posts from '../data';
import styles from '../css/PostDetail.module.css';

function PostDetail() {
  const { id } = useParams();
  const post = posts.find((p) => p.id === parseInt(id));

  if (!post) {
    return <div>Publicación no encontrada</div>;
  }

  return (
    <div className={styles.postDetail}>
      <img src={post.image} alt={post.title} className={styles.postDetailImage} />
      <h1 className={styles.postDetailTitle}>{post.title}</h1>
      <p className={styles.postDetailDate}>{post.date}</p>
      <p className={styles.postDetailAuthor}>{post.author}</p>
      <p className={styles.postDetailDescription}>{post.description}</p>
      <div className={styles.postDetailContent}>
        {post.content.split('\n').map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    </div>
  );
}

export default PostDetail;
