import React from 'react';
import webDevelopmentImg from '../resources/—Pngtree—web development illustration modern_4461019.png';
import gameDevImg from '../resources/th.jpeg';
import aiImg from '../resources/iStock-1005449698-1030x922.jpg';
import curriculumThumbnail from '../resources/curriculum (1).jpg';
import trashSelectorImg from '../resources/publicacion2.jpg'; // Asegúrate de que la ruta es correcta
import '../css/about.css'; // Importa tu CSS para About Me aquí
import SkillCard from './SkillCard'; // Asegúrate de que la ruta es correcta
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import {
  faCss3Alt, faHtml5, faLaravel, faBootstrap, faPython,
  faVuejs, faJsSquare, faGitAlt, faCuttlefish, faJava
} from '@fortawesome/free-brands-svg-icons';
import { faBrain, faUsers, faLeaf, faFire, faDatabase, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';

function AboutMe() {
  const { t } = useTranslation();

  return (
    <div>
      <section id="sobre-mi" className="interest-section">
        <h2 className="section-header">{t('areas_of_interest')}</h2>
        <div className="grid">
          {/* Tarjeta de Desarrollo Web */}
          <div className="card">
            <img className="card__img" src={webDevelopmentImg} alt={t('web_development')} />
            <div className="card__content">
              <h3 className="card__header">{t('web_development')}</h3>
              <p className="card__text">{t('passionate_web')}</p>
            </div>
          </div>
          {/* Tarjeta de Desarrollo de Videojuegos */}
          <div className="card">
            <img className="card__img" src={gameDevImg} alt={t('game_development')} />
            <div className="card__content">
              <h3 className="card__header">{t('game_development')}</h3>
              <p className="card__text">{t('game_dev_enthusiast')}</p>
            </div>
          </div>
          {/* Tarjeta de IA */}
          <div className="card">
            <img className="card__img" src={aiImg} alt={t('artificial_intelligence')} />
            <div className="card__content">
              <h3 className="card__header">{t('artificial_intelligence')}</h3>
              <p className="card__text">{t('ai_interest')}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="skills-section">
        <h2 className="section-header">{t('skills')}</h2>
        <div className="grid skills-grid">
          <SkillCard icon={faBrain} skillName={t('problem_solving')} filledStars={3} />
          <SkillCard icon={faUsers} skillName={t('leadership')} filledStars={2} />
          <SkillCard icon={faLeaf} skillName={t('adaptability_learning')} filledStars={4} />
          <SkillCard icon={faFire} skillName={t('motivation')} filledStars={4} />
          <SkillCard icon={faCss3Alt} skillName={t('css')} filledStars={3} />
          <SkillCard icon={faHtml5} skillName={t('html')} filledStars={3} />
          <SkillCard icon={faLaravel} skillName={t('laravel')} filledStars={4} />
          <SkillCard icon={faBootstrap} skillName={t('bootstrap')} filledStars={3} />
          <SkillCard icon={faPython} skillName={t('python')} filledStars={2} />
          <SkillCard icon={faVuejs} skillName={t('vue_js')} filledStars={1} />
          <SkillCard icon={faJsSquare} skillName={t('javascript')} filledStars={2} />
          <SkillCard icon={faGitAlt} skillName={t('git')} filledStars={3} />
          <SkillCard icon={faDatabase} skillName={t('mysql')} filledStars={3} />
          <SkillCard icon={faDatabase} skillName={t('mongodb')} filledStars={2} />
          <SkillCard icon={faCuttlefish} skillName={t('c_sharp')} filledStars={2} />
          <SkillCard icon={faJava} skillName={t('java')} filledStars={2} />
        </div>
      </section>

      <section className="awards-section">
        <h2 className="section-header">Premios y Reconocimientos</h2>
        <div className="awards-grid">

          <div className="award-card">
            <FontAwesomeIcon icon={faTrophy} className="award-icon" />
            <h3 className="award-title">Segundo Lugar en la Feria de Logros</h3>
            <p className="award-description">Obtuve el segundo lugar en la feria de logros de la Universidad Gerardo Barrios con el proyecto de Inteligencia Artificial "Trash Selector", que identifica diferentes tipos de basura mediante imágenes en tiempo real.</p>
            <img src={trashSelectorImg} alt="Trash Selector Project" className="award-img" />
          </div>
        </div>
      </section>

      <section id="curriculum" className="curriculum-section">
        <h2 className="section-header">Mi Currículum</h2>
        <div className="curriculum-preview">
          <img src={curriculumThumbnail} alt="Vista previa del currículum" className="curriculum-thumbnail" />
          <a href="path/to/your/Erick_Tiznado_Curriculum.pdf" className="btn-download" download="Erick_Tiznado_Curriculum.pdf">Descargar Currículum</a>
        </div>
      </section>
    </div>
  );
}

export default AboutMe;
