import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../css/contactForm.module.css';
import axios from 'axios';

function ContactForm() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/contact', formData);
      alert(t('message_sent_success'));
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      alert(t('message_sent_error'));
    }
  };

  return (
    <div className={styles.contactSection}>
      <h2 className={styles.contactTitle}>{t('contact')}</h2>
      <form className={styles.contactForm} onSubmit={handleSubmit}>
        <div className={styles.contactFormGroup}>
          <label htmlFor="name">{t('name')}:</label>
          <input 
            type="text" 
            id="name" 
            name="name" 
            value={formData.name} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className={styles.contactFormGroup}>
          <label htmlFor="email">{t('email')}:</label>
          <input 
            type="email" 
            id="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className={styles.contactFormGroup}>
          <label htmlFor="subject">{t('subject')}:</label>
          <input 
            type="text" 
            id="subject" 
            name="subject" 
            value={formData.subject} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className={styles.contactFormGroup}>
          <label htmlFor="message">{t('message')}:</label>
          <textarea 
            id="message" 
            name="message" 
            value={formData.message} 
            onChange={handleChange} 
            required 
          ></textarea>
        </div>
        <button type="submit">{t('send')}</button>
      </form>
    </div>
  );
}

export default ContactForm;
