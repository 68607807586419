import React from 'react';
import ContactForm from './ContactForm';
import styles from '../css/contact.module.css';

function Contact() {
  return (
    <div className={styles.contactContainer}>

      <ContactForm />
    </div>
  );
}

export default Contact;
