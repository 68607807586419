import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from '../css/navbar.module.css'; // Actualizado a módulo CSS

function Navbar() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
      .then(() => {
        console.log(`Idioma cambiado a ${language}`);
      })
      .catch(err => {
        console.error('Error al cambiar el idioma:', err);
      });
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <img src={`${process.env.PUBLIC_URL}/logotiz.png`} alt="Logo" className={styles.logo} />
        </div>
        <div className={styles.menuToggle} onClick={toggleMenu}>
          <div className={styles.hamburger} />
          <div className={styles.hamburger} />
          <div className={styles.hamburger} />
        </div>
        <menu className={`${styles.menu} ${menuOpen ? styles.active : ''}`}>
          <Link to="/" className={`${styles.menuItem} ${location.pathname === '/' ? styles.active : ''}`}>{t('home')}</Link>
          <Link to="/about-me" className={`${styles.menuItem} ${location.pathname === '/about-me' ? styles.active : ''}`}>{t('about_me')}</Link>
          <Link to="/projects" className={`${styles.menuItem} ${location.pathname === '/projects' ? styles.active : ''}`}>{t('projects')}</Link>
          <Link to="/posts" className={`${styles.menuItem} ${location.pathname === '/publications' ? styles.active : ''}`}>{t('publications')}</Link>
          <Link to="/reviews" className={`${styles.menuItem} ${location.pathname === '/reviews' ? styles.active : ''}`}>{t('reseñas')}</Link>
          <Link to="/contact" className={`${styles.menuItem} ${location.pathname === '/contact' ? styles.active : ''}`}>{t('contact')}</Link>
          <div className={styles.languageSwitcher}>
            <button onClick={() => changeLanguage('es')}>ES</button>
            <button onClick={() => changeLanguage('en')}>EN</button>
          </div>
        </menu>
      </div>
    </nav>
  );
}

export default Navbar;
