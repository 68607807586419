import React, { useState, useEffect } from 'react';
import ReviewCard from './ReviewCard';
import ReviewFormModal from './ReviewForm';
import styles from '../css/reviews.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

// Define la URL de la API de Netlify directamente aquí
const API_URL = 'https://www.tiznadodev.com/.netlify/functions/reviews';

function Reviews() {
  const [reviews, setReviews] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      const response = await axios.get(API_URL);
      setReviews(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  const addReview = async (newReview) => {
    try {
      const response = await axios.post(API_URL, newReview);
      setReviews([...reviews, response.data]);
    } catch (err) {
      console.error(err);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className={styles.reviewsContainer}>
      <div className={styles.reviewsList}>
        {reviews.map((review, index) => (
          <ReviewCard key={index} review={review} />
        ))}
      </div>
      {isModalOpen && <ReviewFormModal addReview={addReview} toggleModal={toggleModal} />}
      <button className={styles.floatingButton} onClick={toggleModal} title="Agregar Reseña">
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </div>
  );
}

export default Reviews;
