// src/components/PostList.js
import React from 'react';
import Post from './Post';
import posts from '../data';
import styles from '../css/PostList.module.css';

function PostList() {
  return (
    <div className={styles.postList}>
      {posts.map((post) => (
        <Post key={post.id} post={post} />
      ))}
    </div>
  );
}

export default PostList;
