import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';  // Ícono sólido para estrellas llenas
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';  // Ícono regular para estrellas no llenas
import '../css/about.css';  // Verifica que la ruta a tu archivo CSS es correcta

const SkillCard = ({ icon, skillName, totalStars = 5, filledStars }) => {
  const stars = Array.from({ length: totalStars }, (_, index) => index < filledStars);

  return (
    <div className="skill-card">
      <FontAwesomeIcon icon={icon} className="skill-icon" />
      <h3 className="skill-name">{skillName}</h3>
      <div className="stars">
        {stars.map((filled, index) => (
          // Usar ícono regular para estrellas no llenas y sólido para las llenas
          <FontAwesomeIcon key={index} icon={filled ? faStarSolid : faStarRegular} className="star" />
        ))}
      </div>
    </div>
  );
};

export default SkillCard;

