import React, { useState } from 'react';
import styles from '../css/reviewForm.module.css';
import axios from 'axios';

function ReviewFormModal({ addReview, toggleModal }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [profession, setProfession] = useState('');
  const [review, setReview] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addReview({ firstName, lastName, email, profession, review });
    setFirstName('');
    setLastName('');
    setEmail('');
    setProfession('');
    setReview('');
    toggleModal();
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2>Deja tu Testimonio</h2>
        <form className={styles.reviewForm} onSubmit={handleSubmit}>
          <div className={styles.formGroupInline}>
            <div className={styles.formGroup}>
              <label htmlFor="firstName">Nombre:</label>
              <input 
                type="text" 
                id="firstName" 
                value={firstName} 
                onChange={(e) => setFirstName(e.target.value)} 
                required 
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="lastName">Apellido:</label>
              <input 
                type="text" 
                id="lastName" 
                value={lastName} 
                onChange={(e) => setLastName(e.target.value)} 
                required 
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email">Correo Electrónico:</label>
            <input 
              type="email" 
              id="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="profession">Vocación (por ejemplo, su profesión):</label>
            <input 
              type="text" 
              id="profession" 
              value={profession} 
              onChange={(e) => setProfession(e.target.value)} 
              required 
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="review">Tu Testimonio:</label>
            <textarea 
              id="review" 
              value={review} 
              onChange={(e) => setReview(e.target.value)} 
              required 
            ></textarea>
          </div>
          <button type="submit">Enviar Testimonio</button>
        </form>
      </div>
    </div>
  );
}

export default ReviewFormModal;
