import React from 'react';
import { useTranslation } from 'react-i18next';
import profilePic from '../resources/20240304_011522000_iOS.jpg'; // Verifica la ruta
import styles from '../css/estilos.module.css'; // Actualizado a módulo CSS
import AnimatedText from './AnimatedText';

function Home() {
  const { t } = useTranslation();

  return (
    <main className={styles.home}>
      <div className={styles.homeBackground} />
      <div className={styles.homeContent}>
        <div className={styles.homeProfileContainer}>
          <img src={`${process.env.PUBLIC_URL}/logotiz.png`} alt="Logo" className={styles.homeLogo} />
          <div className={styles.homeAboutMeText}>
            <p><span className={styles.homeMyName}>{t('hello_i_am')} Erick Tiznado.</span></p>
            <p>{t('welcome_to_my_portfolio')}</p>
          </div>
          <img src={profilePic} alt={t('profile_picture_alt')} className={styles.homeProfilePic} />
        </div>
        <div className={styles.homeAnimatedTextContainer}>
          <AnimatedText />
        </div>
      </div>
    </main>
  );
}

export default Home;
