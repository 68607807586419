import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import styles from '../css/footer.module.css'; // Actualizado a módulo CSS

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.socialLinks}>
        <a href="https://www.linkedin.com/in/erick-tiznado-28b262204/" target="_blank" rel="noopener noreferrer" className={styles.socialLink}>
          <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
        <a href="tu-twitter-url" target="_blank" rel="noopener noreferrer" className={styles.socialLink}>
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a href="tu-facebook-url" target="_blank" rel="noopener noreferrer" className={styles.socialLink}>
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a href="mailto:tu-email@gmail.com" className={styles.socialLink}>
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
