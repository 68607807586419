import React from 'react';
import styles from '../css/reviewCard.module.css'; // Asegúrate de crear este archivo CSS

function ReviewCard({ review }) {
  return (
    <div className={styles.reviewCard}>
      <h3>{review.firstName} {review.lastName}</h3>
      <p className={styles.profession}>{review.profession}</p>
      <p className={styles.review}>{review.review}</p>
      <p className={styles.email}>Correo de Contacto: {review.email}</p>
    </div>
  );
}

export default ReviewCard;
