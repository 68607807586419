import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from '../css/projects.module.css'; // Actualizado a módulo CSS
import Norris from '../resources/Norris.svg';
import Quizz from '../resources/Quizz.svg';

function Projects() {
  const { t } = useTranslation();
  const projects = [
    {
      name: 'Norris',
      description: t('norris_description'),
      image: Norris,
      role: t('norris_role'),
      responsibilities: [
        t('norris_responsibility1'),
        t('norris_responsibility2'),
        t('norris_responsibility3'),
      ],
      technologies: ['Laravel', 'MySQL', 'HTML', 'CSS', 'JavaScript'],
      process: t('norris_process')
    },
    {
      name: 'Quizz Maker',
      description: t('quizz_maker_description'),
      image: Quizz,
      role: t('quizz_maker_role'),
      responsibilities: [
        t('quizz_maker_responsibility1'),
      ],
      technologies: ['Android Studio', 'Java'],
      process: t('quizz_maker_process')
    }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className={styles.projectsContainer}>
      <Slider {...settings}>
        {projects.map((project, index) => (
          <div key={index} className={styles.projectCardContainer}>
            <div className={styles.projectCard}>
              <img src={project.image} alt={project.name} className={styles.projectImage} />
              <div className={styles.projectInfo}>
                <h3 className={styles.projectName}>{project.name}</h3>
                <p className={styles.projectDescription}>{project.description}</p>
                <h4 className={styles.projectRole}>{t('role')}: {project.role}</h4>
                <h5 className={styles.projectResponsibilitiesTitle}>{t('responsibilities')}:</h5>
                {project.responsibilities.map((responsibility, idx) => (
                  <p key={idx} className={styles.projectResponsibility}>{responsibility}</p>
                ))}
                <h5 className={styles.projectProcessTitle}>{t('process')}:</h5>
                <p className={styles.projectProcess}>{project.process}</p>
                <h5 className={styles.projectTechnologiesTitle}>{t('technologies_used')}:</h5>
                <div className={styles.technologies}>
                  {project.technologies.map((tech, idx) => (
                    <span key={idx} className={styles.technologyPill}>{tech}</span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Projects;
