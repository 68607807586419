import React from 'react';
import { useTranslation } from 'react-i18next';
import '../css/animatedText.css'; // Asegúrate de que la ruta al archivo CSS es correcta

function AnimatedText() {
  const { t } = useTranslation();

  const animatedText = t('animated_text');
  const animatedLetters = animatedText.split('').map((letter, index) =>
    <span key={index} className="m">{letter}</span>
  );

  return (
    <div className='loaderContainer'>
        <h3 className="span loader">
            {animatedLetters}
        </h3>
    </div>
  );
}

export default AnimatedText;
