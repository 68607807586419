// src/components/Post.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../css/Post.module.css';

function Post({ post }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/posts/${post.id}`);
  };

  return (
    <div className={styles.postCard} onClick={handleClick}>
      <img src={post.image} alt={post.title} className={styles.postCardImage} />
      <div className={styles.postCardContent}>
        <h2 className={styles.postCardTitle}>{post.title}</h2>
        <p className={styles.postCardDate}>{post.date}</p>
        <p className={styles.postCardAuthor}>{post.author}</p>
        <p className={styles.postCardDescription}>{post.description}</p>
      </div>
    </div>
  );
}

export default Post;
